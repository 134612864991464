export enum PERMISSIONS {
  QUERY_SEARCH_RETAIL_DATA_IMPORT = 'query/searchretaildataimport',
  MUTATION_IMPORT_CHAT_BOT_QUESTIONS = 'mutation/importchatbotquestions',
  QUERY_SEARCH_AI_SETTINGS = 'query/searchaisettings',

  // live-chats
  QUERY_GET_LIVE_CHAT_AGENT_DATA = 'query/getlivechatagentdata',
  QUERY_GET_LIVE_CHAT_HISTORY = 'query/getlivechathistory',

  // live-chat-setting-teams
  QUERY_GET_LIVE_CHAT_AGENT_TEAMS = 'query/getlivechatagentteams',
  QUERY_GET_LIVE_CHAT_AGENTS = 'query/getlivechatagents',
  QUERY_GET_LIVE_CHAT_CATEGORIES = 'query/getlivechatcategories',
  MUTATION_UPDATE_LIVE_CHAT_AGENT_TEAM_AGENTS = 'mutation/updatelivechatagentteamagents',
  MUTATION_UPDATE_LIVE_CHAT_AGENT_TEAM_CATEGORIES = 'mutation/updatelivechatagentteamcategories',

  // live-chat-setting-agents
  MUTATION_CREATE_LIVE_CHAT_AGENTS = 'mutation/createlivechatagents',
  MUTATION_DELETE_LIVE_CHAT_AGENT = 'mutation/deletelivechatagent',

  // live-chat-setting-templates
  QUERY_SEARCH_LIVE_CHAT_AGENT_ANSWER_TEMPLATES = 'query/searchlivechatagentanswertemplate',
  MUTATION_CREATE_LIVE_CHAT_AGENT_ANSWER_TEMPLATE = 'mutation/createlivechatagentanswertemplate',
  MUTATION_UPDATE_LIVE_CHAT_AGENT_ANSWER_TEMPLATE = 'mutation/updatelivechatagentanswertemplate',
  MUTATION_DELETE_LIVE_CHAT_AGENT_ANSWER_TEMPLATE = 'mutation/deletelivechatagentanswertemplate'
}
